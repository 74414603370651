<template>
  <v-container
    v-if="$isEnabledProperty($ADQUIRIR_SALDO_CTA_CTE)"
    id="crud"
    fluid
    tag="section"
  >
    <!--<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar> </v-card
		>-->
    <v-row
      ><v-col cols="auto" md="8" class="">
        <v-data-table
          :headers="headers"
          :items="productos"
          :search="search"
          :key="productos.id"
          sort-by="secret"
          class="elevation-4 data__table"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="!item.action"
              :color="$cv('btnEditar')"
              title="Agregar Monto"
              @click="agregarProductos(item)"
            >
              mdi-plus-box
            </v-icon>
            <v-icon
              v-else
              :color="$cv('btnEditar')"
              title="Editar Monto"
              @click="editProductos(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat color="white" class="pr-2">
              <v-row>
                <v-col sm="12" class="pt-10">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="'Buscar'"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.nombre`]="{ item }">
            <div class="text-h5 black--text">
              {{ item.nombre }}
            </div>
            <div class="font-weight-light grey--text">
              Codigo Art {{ item.codigo }}
            </div>
          </template>
        </v-data-table>
        <AgregarSaldoCtaCte
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </v-col>
      <Carrito @editarCarrito="editProductos" />
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { find, filter } from "lodash";
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/dashboard/components/secundarios/Carrito.vue";
import AgregarSaldoCtaCte from "@/views/dashboard/components/secundarios/AgregarSaldoCtaCte.vue";

function title() {
  return "Adquirir Saldo en Cta Cte";
}

export default {
  components: {
    Carrito,
    AgregarSaldoCtaCte,
  },

  data: (vm) => ({
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },
    keyTableAutorizados: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Producto", filterable: true, value: "nombre" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    productos: [],
  }),

  created() {},

  mounted() {
    console.log("Componente " + this.title + " creado");
    //this.fetchProductos();
    this.fetchItemsSaldo();
    this.productos = this.itemsSaldo;
    this.actionProd();
    this.actionBtn();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
    ...mapState("saldoCC", ["itemsSaldo"]),
  },

  methods: {
    ...mapMutations("carrito", ["editProducto"]),
    ...mapActions("saldoCC", ["fetchItemsSaldo"]),

    agregarProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.subtotal = 0;
      this.accionProducto = "AGREGAR PRODUCTO";
      this.dialog = true;
    },
    editProductos(item) {
      const producto = find(this.carrito, { id: item.id });
      item = producto;
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR PRODUCTO";
      this.dialog = true;
    },
    actionProd() {
      for (let i = 0; i < this.productos.length; i++) {
        this.productos[i].action = false;
      }
    },
    actionBtn() {
      const IDsC = this.carrito.map((item) => item.id);
      if (this.carrito.length == 0) return;
      for (let i = 0; i < this.carrito.length; i++) {
        // console.log(IDsC[i]);
        this.productos[IDsC[i] - 1].action = true;
      }
    },
  },
  watch: {
    carrito() {
      this.productos = {};
      this.productos = this.itemsSaldo;
      this.actionProd();
      this.actionBtn();
    },
  },
};
</script>

<style>
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
</style>
